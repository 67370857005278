import React from 'react';

const ConcentrationLimitUi = props => {
  const {
    totalConcentratesWeight,
    totalNonConcentratesWeight,
    maxConcentratesWeightAllowed,
    maxNonConcentratesWeightAllowed,
    finalConcentrateUnit,
    finalNonConcentrateUnit,
    concentratesWeightPercentage,
    nonConcentratesWeightPercentage,
    isCartBeyondLimits,
    isMedicalApproved,
    handleDrWeedyApply
  } = props;

  return (
    <div className={`cart-widget-concentrates ${isCartBeyondLimits ? 'dr-weedy-cart-text' : ''}`}>
      <div className="d-flex align-items-center mb-1 justify-content-between">
        <div className="cart-widget-non-conc relative">
          <p className="m-0 fs-12">
            <i className="fs-16 text-primary mr-1 icon-non-conc" />
            Non-Concentrates
          </p>
          <div className="progress conc-non-progress">
            <div
              className={`progress-bar ${nonConcentratesWeightPercentage > 100 ? 'progress-exceed' : ''}`}
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${nonConcentratesWeightPercentage}%` }}
            >
            </div>
          </div>
          <span className="conc-non-value fs-10">
            {`${totalNonConcentratesWeight.toFixed(
              2
            )}${finalNonConcentrateUnit} / ${maxNonConcentratesWeightAllowed}${finalNonConcentrateUnit}`}
          </span>
        </div>

        <div className="cart-widget-conc">
          <p className="m-0 fs-12">
            <i className="fs-16 text-primary mr-1 icon-conc" />
            Concentrates
          </p>

          <div className="progress conc-non-progress">
            <div
              className={`progress-bar ${concentratesWeightPercentage > 100 ? 'progress-exceed' : ''}`}
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${concentratesWeightPercentage}%` }}
            >
            </div>
          </div>
          <span className="fs-10 conc-non-value">
            {`${totalConcentratesWeight.toFixed(
              2
            )}${finalConcentrateUnit} / ${maxConcentratesWeightAllowed}${finalConcentrateUnit}`}
          </span>
        </div>
      </div>

      {isCartBeyondLimits ? (
        <div>
          <p className="text-center pt-2">
            You have exceeded the daily limit of consumption set by DCC. Please remove excess products from your cart.
          </p>

          {!isMedicalApproved && false ? (
            <>
              <div role="presentation" onClick={handleDrWeedyApply} className="dr-weedy-link text-center cursor-pointer">
                {`Get Dr Weedy Card for Higher Limits `}
                <i className="icon-chevron-right-f fs-16 relative" />
              </div>

              <p className="text-center fs-12 md-grey-color mb-0">
                It should take less than 2 minutes. Completely free!
              </p>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ConcentrationLimitUi;
