import React, { useContext } from 'react';

import notificationTypes from '../../constants/modalNotificationConst';

import { useCartConcentration } from '../../hooks/cart';
import { useIdProof } from '../../hooks/User';

import appContext from '../../Context/appContext';

import ConcentrationLimitUi from './ConcentrationLimitUi';
import { useDispatch } from 'react-redux';
import { pushNotificationOverlap } from '../../redux/slices/modalSlice';
import { useCallback } from 'react';

const ConcentrationLimitSection = () => {
  const dispatchRedux = useDispatch()

  const {
    totalConcentratesWeight,
    totalNonConcentratesWeight,
    maxConcentratesWeightAllowed,
    maxNonConcentratesWeightAllowed,
    finalConcentrateUnit,
    finalNonConcentrateUnit,
    concentratesWeightPercentage,
    nonConcentratesWeightPercentage
  } = useCartConcentration();

  const {
    data: { is_medical_approved: isMedicalApproved }
  } = useIdProof();

  const handleDrWeedyApply = useCallback(() => {
    dispatchRedux(pushNotificationOverlap({ type: notificationTypes.showDrWeedyMedicalCard }))
  }, [])

  const isCartBeyondLimits = concentratesWeightPercentage > 100 || nonConcentratesWeightPercentage > 100;

  return (
    <ConcentrationLimitUi
      totalConcentratesWeight={totalConcentratesWeight}
      totalNonConcentratesWeight={totalNonConcentratesWeight}
      maxConcentratesWeightAllowed={maxConcentratesWeightAllowed}
      maxNonConcentratesWeightAllowed={maxNonConcentratesWeightAllowed}
      finalConcentrateUnit={finalConcentrateUnit}
      finalNonConcentrateUnit={finalNonConcentrateUnit}
      concentratesWeightPercentage={concentratesWeightPercentage}
      nonConcentratesWeightPercentage={nonConcentratesWeightPercentage}
      isCartBeyondLimits={isCartBeyondLimits}
      isMedicalApproved={isMedicalApproved}
      handleDrWeedyApply={handleDrWeedyApply}
    />
  );
};

export default ConcentrationLimitSection;
